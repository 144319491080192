import React, { Fragment, useState, useRef, useEffect, useContext } from 'react'
import { AuthContext } from '../../utils/auth.js';
import axios from 'axios';

import { ArrowLeftIcon, Bars3Icon } from '@heroicons/react/20/solid';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

import {
  DocumentIcon,
  UserGroupIcon,
  Bars4Icon,
  EnvelopeIcon,
  CalendarIcon,
  ClockIcon,
  PhotoIcon,
  TableCellsIcon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const items = [
  {
    id: 0,
    title: 'Custom Document',
    description: 'Define custom fields for unique documents not covered by predefined templates.',
    icon: Bars4Icon,
    background: 'bg-indigo-500',
  },
  {
    id: 1,
    title: 'Resume / CV',
    description: 'Extract info from resumes such as name, surname, education, work experience etc.',
    icon: DocumentIcon,
    background: 'bg-blue-500',
    fields: [
      {
        "key": "personal_info",
        "description": "personal information of the person",
        "type": "object",
        "properties": [
          {
            "key": "name",
            "description": "name of the person",
            "example": "Alex Smith",
            "type": "string"
          },
          {
            "key": "email",
            "description": "email of the person",
            "example": "alex.smith@gmail.com",
            "type": "string"
          },
          {
            "key": "phone",
            "description": "phone number of the person",
            "example": "0712 123 123",
            "type": "string"
          },
          {
            "key": "address",
            "description": "address of the person",
            "example": "1234 Main St, New York, NY 10001",
            "type": "string"
          }
        ]
      },
      {
        "key": "work_experience",
        "description": "work experience of the person",
        "type": "array",
        "items": {
          "type": "object",
          "properties": [
            {
              "key": "title",
              "description": "title / position of the job",
              "example": "Software Engineer",
              "type": "string"
            },
            {
              "key": "start_date",
              "description": "start date of the job",
              "example": "2022",
              "type": "string"
            },
            {
              "key": "end_date",
              "description": "end date of the job",
              "example": "2023",
              "type": "string"
            },
            {
              "key": "company",
              "description": "company / organisation of the job",
              "type": "string"
            },
            {
              "key": "location",
              "description": "location of the job",
              "example": "United States of America",
              "type": "string"
            },
            {
              "key": "description",
              "description": "description of the job",
              "example": "Designing and implementing server-side logic to ensure high performance and responsiveness of applications.",
              "type": "string"
            }
          ]
        }
      },
      {
        "key": "education",
        "description": "school education of the person",
        "type": "array",
        "items": {
          "type": "object",
          "properties": [
            {
              "key": "title",
              "description": "title / degree of the education",
              "example": "Master of Science in Computer Science",
              "type": "string"
            },
            {
              "key": "start_date",
              "description": "start date of the education",
              "example": "2022",
              "type": "string"
            },
            {
              "key": "end_date",
              "description": "end date of the education",
              "example": "2023",
              "type": "string"
            },
            {
              "key": "institute",
              "description": "institute / school of the education",
              "example": "Boston Public Library, Science and Technology Department",
              "type": "string"
            },
            {
              "key": "location",
              "description": "location of the education",
              "example": "Boston, Massachusetts",
              "type": "string"
            },
            {
              "key": "description",
              "description": "description of the education",
              "example": "Advanced academic degree focusing on developing a deep understanding of theoretical foundations and practical applications of computer technology.",
              "type": "string"
            }
          ]
        }
      },
      {
        "key": "languages",
        "description": "languages spoken by the person",
        "type": "array",
        "items": {
          "type": "string",
          "example": "English"
        }
      },
      {
        "key": "skills",
        "description": "skills of the person",
        "type": "array",
        "items": {
          "type": "string",
          "example": "NodeJS"
        }
      },
      {
        "key": "certificates",
        "description": "certificates / certifications / accreditation / diplomas of the person",
        "type": "array",
        "items": {
          "type": "string",
          "example": "AWS Certified Developer - Associate"
        }
      }
    ]
  },
  {
    id: 2,
    title: 'Contract',
    description: 'Extract info from contracts such as name, surname, date, amount, price etc.',
    icon: DocumentIcon,
    background: 'bg-red-500',
    fields: [
      {
        "key": "contract_details",
        "description": "contract details",
        "type": "object",
        "properties": [
          {
            "key": "contract_number",
            "example": "56-2024",
            "type": "string",
            "description": "unique identifier for the contract"
          },
          {
            "key": "contract_date",
            "example": "April 20, 2024",
            "type": "string",
            "description": "date the contract was signed"
          }
        ]
      },
      {
        "key": "contractor",
        "description": "information about the party providing the service or fulfilling the obligations of the contract",
        "type": "object",
        "properties": [
          {
            "key": "name",
            "type": "string",
            "description": "name of the party"
          },
          {
            "key": "address",
            "type": "string",
            "description": "address of the party"
          },
          {
            "key": "legal_representative",
            "type": "string",
            "description": "legal representative of the party"
          },
          {
            "key": "position",
            "type": "string",
            "description": "position of the legal representative"
          }
        ]
      },
      {
        "key": "client",
        "description": "information about the party receiving the service or benefiting from the contract",
        "type": "object",
        "properties": [
          {
            "key": "name",
            "type": "string",
            "description": "name of the party"
          },
          {
            "key": "address",
            "type": "string",
            "description": "address of the party"
          },
          {
            "key": "legal_representative",
            "type": "string",
            "description": "legal representative of the party"
          },
          {
            "key": "position",
            "type": "string",
            "description": "position of the legal representative"
          }
        ]
      },
      {
        "key": "contract_object",
        "description": "description of the contract object",
        "type": "string",
        "example": "Design and development of a web application as per the specifications in the attached Project Brief (Document A)"
      },
      {
        "key": "obligations",
        "description": "obligations of the involved parties",
        "type": "object",
        "properties": [
          {
            "key": "contractor_obligations",
            "example": "Design and develop a web application, deliver by September 1, 2024, make necessary adjustments within 30 days of delivery",
            "type": "string",
            "description": "obligations of the contractor"
          },
          {
            "key": "client_obligations",
            "example": "Pay the total fee in stages, test the application and provide feedback within 30 days of delivery",
            "type": "string",
            "description": "obligations of the client"
          }
        ]
      },
      {
        "key": "contract_duration",
        "type": "string",
        "example": "12 months",
        "description": "duration of the contract"
      },
      {
        "key": "contract_price",
        "type": "string",
        "example": "$200,000",
        "description": "total price of the contract"
      }
    ]
  },
  {
    id: 3,
    title: 'Business Card',
    description: 'Extract contact details from business cards such as name, job title, company, phone number, and email.',
    icon: UserGroupIcon,
    background: 'bg-orange-500',
    fields: [
      {
        "key": "name",
        "description": "Name of the person in the business card",
        "example": "John Doe"
      },
      {
        "key": "job_title",
        "description": "Job title of the person",
        "example": "CEO"
      },
      {
        "key": "company_name",
        "description": "Extract company name from card; if absent, deduce from email, website, or social domains.",
        "example": "Acme Inc."
      },
      {
        "key": "address",
        "description": "Address of the company",
        "example": "1234 Main St, New York, NY 10001"
      },
      {
        "key": "phone_numbers",
        "type": "array",
        "description": "Phone numbers of the person",
        "items": {
          "type": "string",
          "example": "123-456-7890"
        }
      },
      {
        "key": "email_addresses",
        "type": "array",
        "description": "Email addresses of the person",
        "items": {
          "type": "string",
          "example": "email@acme.com"
        }
      },
      {
        "key": "website_url",
        "description": "Website url of the company",
        "example": "https://www.acme.com"
      },
      {
        "key": "social_media_handles",
        "description": "Social media handles of the company",
        "type": "array",
        "items": {
          "type": "object",
          "properties": [
            {
              "key": "type",
              "description": "Type of the social media handle",
              "example": "facebook"
            },
            {
              "key": "username",
              "description": "Username of the social media handle",
              "example": "acme"
            }
          ]
        }
      }
    ]
  },
  {
    id: 4,
    title: 'Email',
    description: 'Extract key information from emails such as sender, recipient, subject, date, and body content.',
    icon: EnvelopeIcon,
    background: 'bg-cyan-500',
    fields: [
      {
        "key": "email_info",
        "type": "object",
        "description": "Information about the email",
        "properties": [
          {
            "key": "Subject Line",
            "description": "The subject of the email"
          },
          {
            "key": "Email Date",
            "description": "The date the email was sent",
            "example": "February 20, 2024"
          }
        ]
      },
      {
        "key": "sender_info",
        "type": "object",
        "description": "Information about the sender of the email",
        "properties": [
          {
            "key": "Sender Name",
            "description": "The name of the person who sent the email"
          },
          {
            "key": "Sender Email",
            "description": "The email address of the sender"
          },
          {
            "key": "Sender's Position",
            "description": "The job title of the sender",
            "example": "Project Manager"
          },
          {
            "key": "Sender's Contact Number",
            "description": "The phone number of the sender"
          }
        ]
      },
      {
        "key": "recipient",
        "type": "object",
        "description": "Information about the recipient of the email",
        "properties": [
          {
            "key": "Recipient Name",
            "description": "The name of the email's recipient"
          },
          {
            "key": "Recipient Email",
            "description": "The email address of the recipient"
          }
        ]
      },
      {
        "key": "cc",
        "description": "Carbon copy recipients of the email",
        "type": "array",
        "items": {
          "type": "string",
          "example": "test@gmail.com"
        }
      },
      {
        "key": "bcc",
        "description": "Blind carbon copy recipients of the email",
        "type": "array",
        "items": {
          "type": "string",
          "example": "test@gmail.com"
        }
      },
      {
        "key": "meeting_info",
        "description": "Information about the meeting",
        "type": "object",
        "properties": [
          {
            "key": "Meeting Date",
            "example": "February 25, 2024",
            "description": "The date of the meeting"
          },
          {
            "key": "Meeting Time",
            "example": "10:00 AM",
            "description": "The time of the meeting"
          },
          {
            "key": "Meeting Location",
            "example": "1234 Main St, New York, NY 10001",
            "description": "The location of the meeting"
          }
        ]
      },
      {
        "key": "project_name",
        "description": "The name of the project being discussed"
      },
      {
        "key": "proposal_requirements",
        "description": "Specific requirements or topics requested in the proposal",
        "type": "array",
        "items": {
          "type": "string",
          "example": "Integration of AI technologies"
        }
      }
    ]
  },
  {
    id: 5,
    title: 'Invoice',
    description: 'Extract details from invoices including supplier name, invoice number, date, item descriptions, quantities, prices, and total amount.',
    icon: TableCellsIcon,
    background: 'bg-green-500',
    fields: [
      {
        "key": "invoice_id",
        "description": "invoice id / invoice number / receipt number / invoice no. / billing id / document number / reference number / or something similar of the invoice",
        "example": "1234567890",
        "type": "string"
      },
      {
        "key": "invoice_date",
        "description": "the issuing date / billing date / transaction date of the invoice in the following format yyyy-mm-dd",
        "example": "2022-01-01",
        "type": "string"
      },
      {
        "key": "invoice_tax_rate",
        "description": "the tax rate / vat rate / tax percentage of the invoice",
        "example": "19%",
        "type": "string"
      },
      {
        "key": "invoice_currency",
        "description": "the currency of the invoice. it may also be found as a symbol. interpret the symbol and return one of the following: RON, EUR, USD, LEI, etc.",
        "example": "USD",
        "type": "string"
      },
      {
        "key": "client",
        "description": "the client in the invoice",
        "type": "object",
        "properties": [
          {
            "key": "client_name",
            "description": "name of the client",
            "example": "NovaTech Innovations",
            "type": "string"
          },
          {
            "key": "client_address",
            "description": "address of the client",
            "example": "1234 Main St, New York, NY 10001",
            "type": "string"
          },
          {
            "key": "client_tax_id",
            "description": "tax id or vat id of the client",
            "example": "987654321",
            "type": "string"
          }
        ]
      },
      {
        "key": "merchant",
        "description": "the merchant in the invoice",
        "type": "object",
        "properties": [
          {
            "key": "merchant_name",
            "description": "name of the merchant",
            "example": "Galactic Solutions",
            "type": "string"
          },
          {
            "key": "merchant_address",
            "description": "address of the merchant",
            "example": "789 Elm Rd, Seattle, WA 98109",
            "type": "string"
          },
          {
            "key": "merchant_tax_id",
            "description": "tax id or vat id of the merchant",
            "example": "123987456",
            "type": "string"
          }
        ]
      },
      {
        "key": "items",
        "description": "the items / products / goods in the invoice",
        "type": "array",
        "items": {
          "type": "object",
          "properties": [
            {
              "key": "name",
              "description": "the name of the item",
              "example": "Item 1",
              "type": "string"
            },
            {
              "key": "quantity",
              "description": "the quantity of the item",
              "example": "1",
              "type": "string"
            },
            {
              "key": "unit_price",
              "description": "the unit price of the item. return only the number as a string.",
              "example": "100.00",
              "type": "string"
            },
            {
              "key": "total_price",
              "description": "the total price of the item. return only the number as a string.",
              "example": "100.00",
              "type": "string"
            },
            {
              "key": "tax",
              "description": "the total tax of the item. return only the number as a string.",
              "example": "100.00",
              "type": "string"
            }
          ]
        }
      },
      {
        "key": "invoice_sub_total",
        "description": "The total amount before tax is applied. This may correspond to 'Total' or similar terms before tax addition. Return only the number, no symbols",
        "example": "863.03",
        "type": "string"
      },
      {
        "key": "invoice_tax_amount",
        "description": "The amount of tax charged on the invoice. This is the tax figure listed separately from the sub-total. Return only the number, no symbols",
        "example": "163.97",
        "type": "string"
      },
      {
        "key": "invoice_grand_total",
        "description": "invoice grand total",
        "example": "1027.00",
        "type": "string"
      }
    ]
  },
  {
    id: 6,
    title: 'Receipt',
    description: 'Extract information from receipts such as date, merchant name, items purchased, quantities, prices, and total cost.',
    icon: ViewColumnsIcon,
    background: 'bg-yellow-500',
    fields: [
      {
        "key": "receipt_id",
        "description": "the receipt id / number / transaction id / trans. / payment reference of the receipt",
        "example": "1234567890",
        "type": "string"
      },
      {
        "key": "receipt_date",
        "description": "the receipt date in the following format yyyy-mm-dd. sometimes it may not be specifically mentioned, then just look for a date.",
        "example": "2022-01-01",
        "type": "string"
      },
      {
        "key": "merchant",
        "description": "the merchant / seller / vendor in the receipt",
        "type": "object",
        "properties": [
          {
            "key": "merchant_name",
            "description": "name of the merchant",
            "example": "Galactic Solutions",
            "type": "string"
          },
          {
            "key": "merchant_address",
            "description": "address of the merchant",
            "example": "789 Elm Rd, Seattle, WA 98109",
            "type": "string"
          },
          {
            "key": "merchant_tax_id",
            "description": "tax id or vat id of the merchant",
            "example": "123987456",
            "type": "string"
          }
        ]
      },
      {
        "key": "items",
        "description": "the items in the receipt. Extract all occurrences of each item listed on the receipt, including items with identical names, quantities, and prices. Each instance should be treated as a separate item, even if they have the same details. Ensure that all items are captured individually, reflecting their total count on the receipt.",
        "type": "array",
        "items": {
          "type": "object",
          "properties": [
            {
              "key": "name",
              "example": "Item 1",
              "type": "string",
              "description": "the name of the item"
            },
            {
              "key": "quantity",
              "example": "1",
              "type": "string",
              "description": "the quantity of the item"
            },
            {
              "key": "unit_price",
              "description": "return only the number as a string.",
              "example": "100.00",
              "type": "string"
            },
            {
              "key": "total_price",
              "description": "return only the number as a string.",
              "example": "100.00",
              "type": "string"
            }
          ]
        }
      },
      {
        "key": "total_tax_amount",
        "description": "The amount of tax charged on the receipt. This is the tax figure listed separately from the sub-total. Return only the number as a string.",
        "example": "163.97",
        "type": "string"
      },
      {
        "key": "grand_total",
        "description": "The total amount after tax has been added. This should capture the final payable amount. Return only the number as a string.",
        "example": "1027.00",
        "type": "string"
      }
    ]
  },
  {
    id: 7,
    title: 'Bank Statement',
    description: 'Extract information from bank statements such as date, transaction type, amount, and description.',
    icon: CalendarIcon,
    background: 'bg-rose-500',
    fields: [
      {
        "key": "amount",
        "example": "25",
        "description": "amount as number"
      },
      {
        "key": "amount_text",
        "example": "Twenty-five and no/100",
        "description": "amount as text"
      },
      {
        "key": "bank_address",
        "description": "address of the bank"
      },
      {
        "key": "bank_name",
        "example": "WASHINGTON'S OLDEST NATIONAL BANK FIRST NATIONAL BANK",
        "description": "name of the bank"
      },
      {
        "key": "date",
        "example": "2019-10-13",
        "description": "date as string"
      },
      {
        "key": "memo",
        "example": "world hunger relief",
        "description": "memo as string"
      },
      {
        "key": "payer_name",
        "example": "HON. GERALD R. FORD MRS. BETTY B. FORD",
        "description": "name of the payer"
      },
      {
        "key": "payer_address",
        "example": "1600 PENNSYLVANIA AVE. WASHINGTON, DC 20500",
        "description": "address of the payer"
      },
      {
        "key": "receiver_name",
        "example": "Presiding Bishop, Episcopal Church",
        "description": "name of the receiver"
      },
      {
        "key": "receiver_address",
        "example": "815 Second Avenue, New York, NY 10017",
        "description": "address of the receiver"
      },
      {
        "key": "currency",
        "example": "USD",
        "description": "currency as string, not symbol"
      },
      {
        "key": "micr_raw",
        "example": "A0540D0004A C140611 6C B0000002500B",
        "description": "raw MICR line"
      },
      {
        "key": "account_number",
        "example": "6111611",
        "description": "Part of the MICR line, typically at the end."
      },
      {
        "key": "routing_number",
        "example": "05400004",
        "description": "Part of the MICR line, typically in the middle."
      },
      {
        "key": "check_number",
        "example": "878",
        "description": "Located at the top right of the check."
      }
    ]
  },
  {
    "id": 8,
    "title": "Bill of Lading",
    "description": "Extract information from Bills of Lading such as shipment details, cargo details, freight charges, and legal information.",
    "icon": ViewColumnsIcon,
    "background": "bg-blue-500",
    "fields": [
      {
        "key": "bol_number",
        "description": "The Bill of Lading number.",
        "example": "BL1234567890",
        "type": "string"
      },
      {
        "key": "date_of_issue",
        "description": "The date when the Bill of Lading was issued, in the format yyyy-mm-dd.",
        "example": "2024-05-27",
        "type": "string"
      },
      {
        "key": "carrier",
        "description": "The carrier responsible for the shipment.",
        "type": "object",
        "properties": [
          {
            "key": "carrier_name",
            "description": "Name of the carrier.",
            "example": "Global Shipping Co.",
            "type": "string"
          },
          {
            "key": "carrier_address",
            "description": "Address of the carrier.",
            "example": "4567 Oak St, Miami, FL 33101",
            "type": "string"
          },
        ]
      },
      {
        "key": "shipper",
        "description": "The shipper of the goods.",
        "type": "object",
        "properties": [
          {
            "key": "shipper_name",
            "description": "Name of the shipper.",
            "example": "ABC Manufacturing",
            "type": "string"
          },
          {
            "key": "shipper_address",
            "description": "Address of the shipper.",
            "example": "1234 Elm St, Los Angeles, CA 90001",
            "type": "string"
          },
        ]
      },
      {
        "key": "consignee",
        "description": "The consignee who will receive the goods.",
        "type": "object",
        "properties": [
          {
            "key": "consignee_name",
            "description": "Name of the consignee.",
            "example": "XYZ Distributors",
            "type": "string"
          },
          {
            "key": "consignee_address",
            "description": "Address of the consignee.",
            "example": "1234 Main St, New York, NY 10001",
            "type": "string"
          }
        ]
      },
      {
        "key": "shipment_details",
        "description": "Details about the shipment.",
        "type": "object",
        "properties": [
          {
            "key": "port_of_loading",
            "description": "Port where the goods are loaded.",
            "example": "Port of Los Angeles",
            "type": "string"
          },
          {
            "key": "port_of_discharge",
            "description": "Port where the goods will be discharged.",
            "example": "Port of Shanghai",
            "type": "string"
          },
          {
            "key": "place_of_receipt",
            "description": "Place where the goods were received.",
            "example": "New York, NY",
            "type": "string"
          },
          {
            "key": "place_of_delivery",
            "description": "Place where the goods will be delivered.",
            "example": "Shanghai, China",
            "type": "string"
          },
          {
            "key": "vessel_name",
            "description": "Name of the vessel transporting the goods.",
            "example": "MSC Harmony",
            "type": "string"
          },
          {
            "key": "voyage_number",
            "description": "Voyage number of the shipment.",
            "example": "V1234",
            "type": "string"
          },
          {
            "key": "container_numbers",
            "description": "List of container numbers.",
            "type": "string",
          }
        ]
      },
      {
        "key": "cargo_details",
        "description": "Details about the cargo.",
        "type": "array",
        "items": {
          "type": "object",
          "properties": [
            {
              "key": "description_of_goods",
              "description": "Description of the goods being shipped.",
              "example": "Electronics",
              "type": "string"
            },
            {
              "key": "quantity",
              "description": "Quantity of the goods.",
              "example": "100",
              "type": "string"
            },
            {
              "key": "weight",
              "description": "Weight of the goods.",
              "example": "1000 kg",
              "type": "string"
            },
            {
              "key": "volume",
              "description": "Volume of the goods.",
              "example": "10 cbm",
              "type": "string"
            },
            {
              "key": "marks_and_numbers",
              "description": "Marks and numbers on the goods.",
              "example": "M/N 12345",
              "type": "string"
            },
            {
              "key": "packaging_type",
              "description": "Type of packaging used for the goods.",
              "example": "Cartons",
              "type": "string"
            }
          ],
        },
      },
      {
        "key": "freight_and_charges",
        "description": "Details about freight and charges.",
        "type": "object",
        "properties": [
          {
            "key": "freight_amount",
            "description": "The amount of freight charges.",
            "example": "1500.00",
            "type": "string"
          },
          {
            "key": "freight_terms",
            "description": "Freight terms (Prepaid/Collect).",
            "example": "Prepaid",
            "type": "string"
          },
          {
            "key": "currency",
            "description": "Currency of the freight charges.",
            "example": "USD",
            "type": "string"
          }
        ]
      }
    ]
  }

]

function TemplateStep({ nextStep, previousStep, parentParams, isEdit }) {
  const selectTemplate = (templateId) => {
    // console.log('selected template', templateId);
    setSelectedTemplateId(templateId);
  }

  const continueTemplate = () => {
    if (selectedTemplateId === null) {
      alert('Please select a template');
      return;
    }

    const idsForTableInside = [5, 6, 8];
    if (idsForTableInside.includes(selectedTemplateId)) {
      // update in the parentParams.options object the 'hasTable' property. possible options is null. if not null, then retreive the old options and update the hasTable property
      if (parentParams.options) {
        parentParams.options.hasTable = true;
      } else {
        parentParams.options = { hasTable: true, handwrittenTextRecognition: false, checkboxRecognition: false };
      }
    } else {
      parentParams.options = { hasTable: false, handwrittenTextRecognition: false, checkboxRecognition: false };
    }

    const idsForLongDocuments = [2];
    if (idsForLongDocuments.includes(selectedTemplateId)) {
      // update in the parentParams.options object the 'isLongDocument' property. possible options is null. if not null, then retreive the old options and update the isLongDocument property
      if (parentParams.options) {
        parentParams.options.longDocument = true;
      } else {
        parentParams.options = { hasTable: false, handwrittenTextRecognition: false, checkboxRecognition: false, longDocument: true };
      }
    }

    // update the fields in the parentParams object
    if (items[selectedTemplateId].fields) {
      parentParams.fields = items[selectedTemplateId].fields;
    }

    // update the parentParams.name with title of template or ''
    // parentParams.name = items[selectedTemplateId].title;

    nextStep(parentParams);
  }

  const [selectedTemplateId, setSelectedTemplateId] = useState(0);

  return (
    <div className="bg-white sm:rounded-lg">
      <div className="">
        {/* back button */}
        {/* <button
          onClick={previousStep}
          className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <ArrowLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Back
        </button> */}

        {/* title and subtitle */}
        <h3 className="text-base font-semibold leading-6 text-gray-900">Choose Your Template</h3>
        <div className="mt-1 max-w-xl text-sm text-gray-500">
          <p>Start with a pre-designed template or create a custom document</p>
        </div>

        {/* template grid */}
        <TemplateGrid
          selectedTemplateId={selectedTemplateId}
          selectTemplate={selectTemplate}
        />

        {/* next button */}
        <div className="sm:col-span-4">
          <div className="col-span-full flex justify-end w-full">
            <button
              onClick={continueTemplate}
              type="submit"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function TemplateGrid({ selectTemplate, selectedTemplateId }) {
  return (
    <div>
      {/* <h2 className="text-base font-semibold leading-6 text-gray-900">Projects</h2>
      <p className="mt-1 text-sm text-gray-500">
        You haven’t created a project yet. Get started by selecting a template or start from an empty project.
      </p> */}

      <ul className="mt-6 grid grid-cols-1 gap-6 border-gray-200 py-6 sm:grid-cols-3">
        {items.map((item, itemIdx) => (
          <li key={itemIdx} className="flow-root">
            <div
              className={
                classNames(
                  "relative -m-2 flex items-center space-x-4 rounded-xl px-4 py-5 focus-within:border focus-within:border-indigo-500 hover:bg-gray-50",
                  selectedTemplateId === item.id ? 'border border-indigo-500 bg-gray-50' : 'border border-gray-200'
                )}
            >
              <div
                className={classNames(
                  'bg-indigo-500',
                  // item.background,
                  'flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg'
                )}
              >
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-900">
                  <button onClick={() => selectTemplate(item.id)} className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <span>{item.title}</span>
                    {/* <span aria-hidden="true"> &rarr;</span> */}
                  </button>
                </h3>
                <p className="mt-1 text-sm text-gray-500 line-clamp-3" >{item.description}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {/* place items at end row */}
      {/* <div className="mt-4 flex justify-end">
        <button onClick={skipTemplate} className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
          Or start from an empty project
          <span aria-hidden="true"> &rarr;</span>
        </button>
      </div> */}
    </div>
  )
}

export default TemplateStep
